<template>
  <b-container v-if="!isLoading" fluid>
    <AnalyticsHeader
      headerTitle="DELIVERY STATUS DASHBOARD"
      @onDateChange="updateDate"
      @extractAnalysis="extractAnalysis"
    />
    <AnalyticsIcons :iconData="iconData" :itemsPerRow="4" />
    <!-- <Chart
      v-show="filterBy === 'monthly'"
      :selectedFilterBy="filterBy"
      :chartOptions="chartOptionsMonthly"
      @filterGraphBy="filterGraphBy"
    />
    <Chart
      v-show="filterBy === 'daily'"
      :selectedFilterBy="filterBy"
      :chartOptions="chartOptionsDaily"
      @filterGraphBy="filterGraphBy"
    /> -->
  </b-container>
</template>

<script>
import AnalyticsHeader from "./components/AnalyticsHeader.vue";
import AnalyticsIcons from "./components/AnalyticsIcons.vue";
import Chart from "../../../components/Chart.vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    AnalyticsHeader,
    AnalyticsIcons,
    Chart,
  },

  async created() {
    this.getAnalyticsData();
    this.isLoading = false;
  },

  methods: {
    updateDate(date, type) {
      if (type === "FROM") {
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
      this.getAnalyticsData();
    },

    getAnalyticsData() {
      this.$http
        .get(`analytics/delivery-status/${this.fromDate}/${this.toDate}`)
        .then((response) => {
          if (response.data.status) {
            this.chartData = response.data.data;
            this.getCountData();

            //UPDATE MONTHLY CHART
            // this.chartOptionsMonthly = {
            //   ...this.chartOptionsMonthly,
            //   xAxisData: this.$helpers.getMonths(this.fromDate, this.toDate),
            // };

            // const { firstMonth, lastMonth } = this.$helpers.getFirstAndLastMonths(
            //   this.chartOptionsMonthly.xAxisData
            // );

            // this.chartOptionsMonthly.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.active.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.assigned.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 2:
            //       series.data = this.chartData.in_warehouse.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.in_transit.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 4:
            //       series.data = this.chartData.out_for_delivery.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 5:
            //       series.data = this.chartData.delivered.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 6:
            //       series.data = this.chartData.closed_store.monthly(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     default:
            //       break;
            //   }
            // });

            //UPDATE DAILY CHART
            // this.chartOptionsDaily = {
            //   ...this.chartOptionsDaily,
            //   xAxisData: this.$helpers.getDays(this.fromDate, this.toDate),
            // };

            // const { firstDay, lastDay } = this.$helpers.getFirstAndLastDays(
            //   this.chartOptionsDaily.xAxisData
            // );

            // this.chartOptionsDaily.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.active.daily(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.assigned.daily(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 2:
            //       series.data = this.chartData.in_warehouse.daily(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.in_transit.daily(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 4:
            //       series.data = this.chartData.out_for_delivery.daily(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 5:
            //       series.data = this.chartData.delivered.daily(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 6:
            //       series.data = this.chartData.closed_store.daily(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     default:
            //       break;
            //   }
            // });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },

    getCountData() {
      this.iconData.forEach((item, i) => {
        switch (i) {
          case 0:
            item.value = this.chartData.active.count;
            break;
          case 1:
            item.value = this.chartData.assigned.count;
            break;
          case 2:
            item.value = this.chartData.in_warehouse.count;
            break;
          case 3:
            item.value = this.chartData.in_transit.count;
            break;
          case 4:
            item.value = this.chartData.out_for_delivery.count;
            break;
          case 5:
            item.value = this.chartData.delivered.count;
            break;
          case 6:
            item.value = this.chartData.closed_store.count;
            break;
          default:
            break;
        }
      });
    },

    onChangeExportType(type) {
      this.exportType = type;
    },

    async extractAnalysis() {
      const response = await this.$http.get(
        `analytics_report/delivery-status/${this.fromDate}/${this.toDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `DeliveryStatusAnalytics_${this.fromDate}-${this.toDate}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    filterGraphBy(filter) {
      this.filterBy = filter;
    },
  },

  data() {
    return {
      isLoading: true,
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      filterBy: "daily",
      exportType: "CSV",
      chartData: {},
      iconData: [
        {
          variant: "dark",
          icon: "BoxIcon",
          value: 0,
          label: "Active",
        },
        {
          variant: "orange",
          icon: "UserIcon",
          value: 0,
          label: "Assigned",
        },
        {
          variant: "turquoise",
          icon: "StarIcon",
          value: 0,
          label: "In Warehouse",
        },
        {
          variant: "info",
          icon: "TruckIcon",
          value: 0,
          label: "In Transit",
        },
        {
          variant: "light-blue",
          icon: "NavigationIcon",
          value: 0,
          label: "Out For Delivery",
        },
        {
          variant: "success",
          icon: "SmileIcon",
          value: 0,
          label: "Delivered",
        },
        {
          variant: "maroon",
          icon: "XCircleIcon",
          value: 0,
          label: "Closed Store",
        },
      ],
      dailyXAxis: [],
      monthlyXAxis: [],
      chartOptionsDaily: {
        legend: {
          left: "left",
          data: [
            "Active",
            "Assigned",
            "In Warehouse",
            "In Transit",
            "Out For Delivery",
            "Delivered",
            "Closed Store",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "Active",
            type: "line",
            data: [],
            itemStyle: { color: "#4b4b4b" },
          },
          {
            name: "Assigned",
            type: "line",
            data: [],
            itemStyle: { color: "#e8833a" },
          },
          {
            name: "In Warehouse",
            type: "line",
            data: [],
            itemStyle: { color: "#1aae9f" },
          },
          {
            name: "In Transit",
            type: "line",
            data: [],
            itemStyle: { color: "#000F77" },
          },
          {
            name: "Out For Delivery",
            type: "line",
            data: [],
            itemStyle: { color: "#3498db" },
          },
          {
            name: "Delivered",
            type: "line",
            data: [],
            itemStyle: { color: "#2FE966" },
          },
          {
            name: "Closed Store",
            type: "line",
            data: [],
            itemStyle: { color: "#d3455b" },
          },
        ],
      },
      chartOptionsMonthly: {
        legend: {
          left: "left",
          data: [
            "Active",
            "Assigned",
            "In Warehouse",
            "In Transit",
            "Out For Delivery",
            "Delivered",
            "Closed Store",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "Active",
            type: "line",
            data: [],
            itemStyle: { color: "#4b4b4b" },
          },
          {
            name: "Assigned",
            type: "line",
            data: [],
            itemStyle: { color: "#e8833a" },
          },
          {
            name: "In Warehouse",
            type: "line",
            data: [],
            itemStyle: { color: "#1aae9f" },
          },
          {
            name: "In Transit",
            type: "line",
            data: [],
            itemStyle: { color: "#000F77" },
          },
          {
            name: "Out For Delivery",
            type: "line",
            data: [],
            itemStyle: { color: "#3498db" },
          },
          {
            name: "Delivered",
            type: "line",
            data: [],
            itemStyle: { color: "#2FE966" },
          },
          {
            name: "Closed Store",
            type: "line",
            data: [],
            itemStyle: { color: "#d3455b" },
          },
        ],
      },
    };
  },
};
</script>

<style></style>
